import {useAuth} from "../contexts/AccessTokenContext";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {API_BASE_URL_WITH_PORT} from "../Config";
import {useParams} from "react-router-dom";
import AdListItems from "../components/AdListItems";

const UsersPage = () => {
    const {id} = useParams();
    const {accessToken, isLoading, isAuthenticated, loginWithRedirect} = useAuth();
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const [user, setUser] = useState({});
    const [ads, setAds] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                if (accessToken) {
                    headers['Authorization'] = `Bearer ${accessToken}`;
                }
                const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/user/${id}`, {
                    headers: headers,
                });

                const {user, ads} = await response.json();
                setAds(ads);
                setUser(user);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [accessToken]);

    return (
        <div className="max-w-md mx-auto mt-4 p-4 bg-white rounded-md shadow-md md:max-w-3xl lg:max-w-4xl">
            <h2 className="text-2xl font-bold mb-4">{t("Profile")}</h2>
            <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    {t("Name")}:
                </label>
                <input
                    type="text"
                    name="name"
                    value={user.name}
                    className="mt-1 p-2 border rounded-md w-full"
                />
            </div>
            {isAuthenticated ? (
                <>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            E-mail:
                        </label>
                        <input
                            type="text"
                            name="email"
                            value={user.email}
                            readOnly
                            className="mt-1 p-2 border rounded-md w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                            {t("Phone number")}:
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={user.phoneNumber}
                            className="mt-1 p-2 border rounded-md w-full"
                        />
                    </div>
                </>
            ) : (
                <div className="border border-orange-400 rounded-md p-4 mb-4 flex items-center mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mr-4 text-orange-400"
                         viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                         strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"/>
                        <line x1="12" y1="16" x2="12" y2="12"/>
                        <line x1="12" y1="8" x2="12" y2="8"/>
                    </svg>
                    <p className="text-sm text-orange-400">
                        {t("Personal data of the advertiser is only accessible to logged-in users. Please")}{" "}
                        <span onClick={loginWithRedirect}
                              className="text-blue-500 cursor-pointer underline hover:text-blue-600 focus:outline-none focus:underline">
                                            {t("Login")}
                                        </span>{"."}
                    </p>
                </div>
            )}
            <div className="mb-4">
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    {t("Address")}:
                </label>
            </div>
            <h2 className="text-xl font-semibold mb-4">{t("User's advertisements")}:</h2>
            {<AdListItems ads={ads} ownAds={false}/>}
        </div>
    );
}

export default UsersPage;