import React from 'react';
import {t} from "i18next";

const LoadingSpinner = ({center}) => {
    return (
        <div className={`flex flex-col items-center py-10 ${center ? 'justify-center h-screen' : ''}`}>
            <div className="rounded-full h-12 w-12 border-4 loadingBorder border-t-0 border-r-0 animate-spin"></div>
            <div className="mt-2">
                <p className="font-semibold text-gray-600">{t("Loading...")}</p>
            </div>
        </div>
    );
}

export default LoadingSpinner;
