export default class Utils {
    static async fetchMapyCzOptions(inputValue, apiKey) {
        try {
            const fetchData = await fetch(
                `https://api.mapy.cz/v1/suggest?lang=cs&limit=10&apikey=${apiKey}&query=${inputValue}&locality=cz,sk,at`
            );
            const jsonData = await fetchData.json();

            return jsonData.items.map((item) => ({
                value: `${item.name}, ${item.location}`,
                label: `${item.name}, ${item.location}`,
                data: {
                    name: item.name,
                    location: item.location,
                    latitude: item.position.lat,
                    longitude: item.position.lon,
                    region: Utils.extractRegion(item),
                },
            }));
        } catch (exc) {
            console.log(exc);
            return [];
        }
    }

    static extractRegion(item) {
        let index = 0;
        for (const element of item.regionalStructure) {
            if (element.type === 'regional.region') {
                index++;
                if (index === 2) {
                    return element.name;
                }
            }
        }
        return null;
    }
}
