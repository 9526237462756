import React, {useEffect, useState} from 'react';
import {API_BASE_URL_WITH_PORT, API_MAPY_CZ_KEY} from '../Config';
import {useTranslation} from "react-i18next";
import {Disclosure} from "@headlessui/react";
import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";
import AsyncSelect from "react-select/async";
import Utils from "../Utils";

function Filters({mobile, filter, setFilter}) {
    const [minPriceConst, setMinPriceConst] = useState(0);
    const [maxPriceConst, setMaxPriceConst] = useState(0);
    const [regions, setRegions] = useState([]);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState({});
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        fetchFilters();
    }, []);


    const fetchFilters = async () => {
        try {
            const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/ad/filters`);
            if (response.ok) {
                const data = await response.json();
                setRegions(data.regions);
                setMinPriceConst(data.minPrice);
                setMaxPriceConst(data.maxPrice);
            } else {
                console.error('Failed to fetch filters:', response.statusText);
            }
        } catch (error) {
            console.error('Error while fetching filters:', error);
        }
    };


    const handleMaxPriceChange = (event) => {
        setFilter({...filter, maxPrice: parseInt(event.target.value)})
    };

    const handleMinPriceChange = (event) => {
        setFilter({...filter, minPrice: parseInt(event.target.value)})
    };

    const handleRadiusChange = (event) => {
        setFilter({...filter, radius: parseInt(event.target.value)})
    };

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        setFilter({
            ...filter, [name]: checked,
        });
    };

    const handleAddressChange = (selectedOption) => {
        setFilter({...filter, address: selectedOption})
    };


    const handleRegionChange = (regionKey) => {
        const isSelected = filter.regions?.includes(regionKey);
        let updatedRegions;
        if (isSelected) {
            updatedRegions = filter.regions?.filter(region => region !== regionKey);
        } else {
            updatedRegions = [...(filter.regions || []), regionKey];
        }
        setFilter({...filter, regions: updatedRegions});
    };

    const fetchOptions = async (inputValue) => {
        const options = Utils.fetchMapyCzOptions(inputValue, API_MAPY_CZ_KEY);
        setLoading(false);
        return options;
    };


    return (
        <>
            {mobile ? (
                <>
                    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Transaction type")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-6">
                                        <div className="flex items-center">
                                            <input
                                                id={`filter-mobile-transactionType-offer`}
                                                name="offer"
                                                checked={filter.offer}
                                                type="checkbox"
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                                htmlFor={`filter-mobile-transactionType-offer`}
                                                className="ml-3 min-w-0 flex-1 text-gray-500"
                                            >
                                                {t("Offer")}
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id={`filter-mobile-transactionType-demand`}
                                                name="demand"
                                                checked={filter.demand}
                                                type="checkbox"
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                                htmlFor={`filter-mobile-transactionType-demand`}
                                                className="ml-3 min-w-0 flex-1 text-gray-500"
                                            >
                                                {t("Demand")}
                                            </label>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Region")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-6">
                                        {regions.map((region, index) => {
                                            const regionKey = Object.keys(region)[0];
                                            const regionValue = region[regionKey];
                                            const isChecked = filter.regions?.includes(regionKey);

                                            return (
                                                <div key={index} className="flex items-center">
                                                    <input
                                                        id={`filter-region-${regionKey}`}
                                                        name="region"
                                                        defaultValue={regionKey}
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={() => handleRegionChange(regionKey)}
                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                    />
                                                    <label
                                                        htmlFor={`filter-region-${regionKey}`}
                                                        className="ml-3 min-w-0 flex-1 text-gray-500"
                                                    >
                                                        {regionKey} ({regionValue})
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Price filter")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-6">
                                        <div className="flex ">
                                            <div className="text-sm font-medium leading-6 text-gray-900">OD</div>
                                            <div className="text-sm font-medium leading-6 text-gray-900 ml-40">DO</div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="relative w-64 flex items-center space-x-2">
                                                <input
                                                    type="number"
                                                    id="minPrice"
                                                    placeholder="min"
                                                    value={filter.minPrice}
                                                    onChange={handleMinPriceChange}
                                                    className="w-20 p-1 border border-gray-300 rounded"
                                                />
                                                <span>Kč</span>
                                            </div>
                                            <div className="relative w-64 ml-14 flex items-center space-x-2">
                                                <input
                                                    type="number"
                                                    id="maxPrice"
                                                    placeholder="max"
                                                    value={filter.maxPrice}
                                                    onChange={handleMaxPriceChange}
                                                    className="w-20 p-1 border border-gray-300 rounded"
                                                />
                                                <span>Kč</span>
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Nearby ads")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-6">
                                        <AsyncSelect
                                            className="w-64"
                                            value={filter.address}
                                            onChange={handleAddressChange}
                                            loadOptions={fetchOptions}
                                            placeholder={t("Enter your address...")}
                                            isClearable
                                            isLoading={loading}
                                            noOptionsMessage={() => t("No results found")}
                                            loadingMessage={() => t("Loading...")}
                                        />
                                        <div className="flex items-center space-x-2">
                                            <span>{t("Distance")}:</span>
                                            <input
                                                type="number"
                                                id="radius"
                                                className="w-20 p-1 border border-gray-300 rounded"
                                                placeholder="50"
                                                value={filter.radius}
                                                onChange={handleRadiusChange}
                                            />
                                            <span>km</span>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </>
            ) : (
                <>
                    <Disclosure as="div" className="border-b border-gray-200 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Transaction type")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-4">
                                        <div className="flex items-center">
                                            <input
                                                id={`filter-transactionType-offer`}
                                                name="offer"
                                                checked={filter.offer}
                                                type="checkbox"
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                                htmlFor={`filter-transactionType-offer`}
                                                className="ml-3 text-sm text-gray-600"
                                            >
                                                {t("Offer")}
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id={`filter-transactionType-demand`}
                                                name="demand"
                                                checked={filter.demand}
                                                type="checkbox"
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                                htmlFor={`filter-transactionType-demand`}
                                                className="ml-3 text-sm text-gray-600"
                                            >
                                                {t("Demand")}
                                            </label>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="border-b border-gray-200 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Region")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-4">
                                        {regions.map((region, index) => {
                                            const regionKey = Object.keys(region)[0];
                                            const regionValue = region[regionKey];
                                            const isChecked = filter.regions?.includes(regionKey);

                                            return (
                                                <div key={index} className="flex items-center">
                                                    <input
                                                        id={`filter-region-${regionKey}`}
                                                        name="region"
                                                        defaultValue={regionKey}
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={() => handleRegionChange(regionKey)}
                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                    />
                                                    <label
                                                        htmlFor={`filter-region-${regionKey}`}
                                                        className="ml-3 text-sm text-gray-600"
                                                    >
                                                        {regionKey} ({regionValue})
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="border-b border-gray-200 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Price filter")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-4">
                                        <div className="flex justify-between">
                                            <div className="text-sm font-medium leading-6 text-gray-900">OD</div>
                                            <div className="text-sm font-medium leading-6 text-gray-900 ml-36">DO</div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="relative w-64 flex items-center space-x-2">
                                                <input
                                                    type="number"
                                                    id="minPrice"
                                                    placeholder="min"
                                                    value={filter.minPrice}
                                                    onChange={handleMinPriceChange}
                                                    className="w-20 p-1 border border-gray-300 rounded"
                                                />
                                                <span>Kč</span>
                                            </div>
                                            <div className="relative w-64 ml-14 flex items-center space-x-2">
                                                <input
                                                    type="number"
                                                    id="maxPrice"
                                                    placeholder="max"
                                                    value={filter.maxPrice}
                                                    onChange={handleMaxPriceChange}
                                                    className="w-20 p-1 border border-gray-300 rounded"
                                                />
                                                <span>Kč</span>
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="border-b border-gray-200 py-6">
                        {({open}) => (
                            <>
                                <h3 className="-my-3 flow-root">
                                    <Disclosure.Button
                                        className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                        <span className="font-medium text-gray-900">{t("Nearby ads")}</span>
                                        <span className="ml-6 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                )}
                            </span>
                                    </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                    <div className="space-y-4 flex flex-col items-start">
                                        <AsyncSelect
                                            className="w-64"
                                            value={filter.address}
                                            onChange={handleAddressChange}
                                            loadOptions={fetchOptions}
                                            placeholder={t("Enter your address...")}
                                            isClearable
                                            isLoading={loading}
                                            noOptionsMessage={() => t("No results found")}
                                            loadingMessage={() => t("Loading...")}
                                        />
                                        <div className="flex items-center space-x-2">
                                            <span>{t("Distance")}:</span>
                                            <input
                                                type="number"
                                                id="radius"
                                                className="w-20 p-1 border border-gray-300 rounded"
                                                placeholder="50"
                                                value={filter.radius}
                                                onChange={handleRadiusChange}
                                            />
                                            <span>km</span>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </>
            )}
        </>
    );
}

export default Filters;
