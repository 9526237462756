import {useTranslation} from "react-i18next";
import {Menu, Popover, Transition} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import React, {Fragment, useEffect, useState} from "react";
import {HeartIcon} from "@heroicons/react/24/solid";
import {Link} from "react-router-dom";
import {useAuth} from "../contexts/AccessTokenContext";
import natureImage from "../images/nature.jpg";
import {API_BASE_URL, API_BASE_URL_WITH_PORT} from "../Config";

const Header = ({triggerToggle}) => {
    const {isAuthenticated, loginWithRedirect, logout, accessToken, user} = useAuth();
    const {t} = useTranslation();
    const [favoriteAds, setFavoriteAds] = useState([]);

    const userNavigation = isAuthenticated
        ? [
            {name: t("My profile"), to: '/profile'},
            {name: t("My advertisements"), to: '/my-ads'},
            {name: t("Logout"), handleClick: logout},
        ]
        : [
            {name: t("Add advertisement"), to: '/benefits'},
            {name: t("Login"), handleClick: loginWithRedirect},
        ];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function getInitials(text) {
        const words = text.split(" ");
        const firstInitial = words[0] ? words[0][0] : '';
        const secondInitial = words[1] ? words[1][0] : '';
        return `${firstInitial}${secondInitial}`;
    }

    useEffect(() => {
        const fetchFavoriteAds = async () => {
            try {
                if (accessToken !== null) {
                    const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/adAuth/favoriteAds`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setFavoriteAds(await response.json());
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchFavoriteAds();
    }, [accessToken, triggerToggle]);

    return (
        <>
            <Popover
                as="header"
                className={({open}) =>
                    classNames(
                        open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
                        'bg-white shadow-sm lg:static lg:overflow-y-visible'
                    )
                }
            >
                {({open}) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-3"
                             style={{
                                 backgroundImage: `url(${natureImage})`,
                                 backgroundSize: 'cover',
                                 backgroundPosition: 'center',
                             }}
                        >
                            <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
                                <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                                    <div className="flex flex-shrink-0 items-center">
                                        <Link to={"/"}>
                                            <img
                                                className="h-8 w-auto"
                                                src="favicon.ico"
                                                alt="Your Company"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                                </div>
                                <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                                    {/* Mobile menu button */}
                                    <Popover.Button
                                        className="relative -mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="absolute -inset-0.5"/>
                                        <span className="sr-only">Open menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                        )}
                                    </Popover.Button>
                                </div>
                                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                                    {isAuthenticated &&
                                        <Menu as="div" className="relative ml-5 flex-shrink-0">
                                            <div>
                                                <Menu.Button
                                                    className="relative flex rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    <span className="absolute -inset-1.5"/>
                                                    <span className="sr-only">{t("Favorite advertisements")}</span>
                                                    <HeartIcon className="h-6 w-6 text-red-500" aria-hidden="true"/>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {favoriteAds?.map((ad) => (
                                                        <Menu.Item key={ad.id}>
                                                            {({active}) => (
                                                                <Link to={`/ad/${ad.id}`}
                                                                      className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                                                    <div className="bg-white p-4 shadow-md rounded-md">
                                                                        <h3 className="text-xl font-bold mb-2">{ad.title}</h3>
                                                                        <p className="text-gray-600">{ad.description}</p>

                                                                        <div
                                                                            className="flex justify-between mt-4 text-gray-500">
                                                                            <div>{new Date(ad.creationDate).toLocaleDateString('cs-CZ')}</div>
                                                                        </div>

                                                                        <div>
                                                                            <span
                                                                                className="font-bold">{t("Category")}: </span> {ad.adType !== ad.category ? t(ad.adType) + " - " + t(ad.category) : t(ad.adType)}
                                                                        </div>

                                                                        <div>
                                                                            <span
                                                                                className="font-bold">{t("Price")}:</span> {ad.price} Kč
                                                                        </div>
                                                                        {ad.address && ad.address.postCodeWithCity && (
                                                                            <div>
                                                                                <span
                                                                                    className="font-bold">{t("Locality")}:</span> {ad.address.postCodeWithCity}
                                                                            </div>
                                                                        )}

                                                                        {ad.imagePath && ad.imagePath.length > 0 && (
                                                                            <div className="mt-4">
                                                                                <img
                                                                                    src={`${API_BASE_URL}/${ad.imagePath}`}
                                                                                    alt={`Image ${ad.imagePath}`}
                                                                                    className="my-2"/>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    }

                                    {/* Profile dropdown */}
                                    <Link
                                        to={isAuthenticated ? "/add-advertisement" : "/benefits"}
                                        className="ml-6 inline-flex items-center rounded-md button px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {t("Add advertisement")}
                                    </Link>

                                    {isAuthenticated ||
                                        <Link
                                            to=""
                                            onClick={loginWithRedirect}
                                            className="ml-6 inline-flex items-center rounded-md button px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            {t("Login")}
                                        </Link>
                                    }

                                    {isAuthenticated &&
                                        <Menu as="div" className="relative ml-5 flex-shrink-0">
                                            <div>
                                                <Menu.Button
                                                    className="relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    <span className="absolute -inset-1.5"/>
                                                    <span className="sr-only">Open user menu</span>
                                                    <span
                                                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                                    <span
                                                        className="text-sm font-medium leading-none text-white">{user.name && getInitials(user.name)}</span>
                                                    </span>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {userNavigation.map((item) => (
                                                        item.to !== '/benefits' &&
                                                        <Menu.Item key={item.name}>
                                                            {({active}) => (
                                                                item.to ? (
                                                                    <Link
                                                                        to={item.to}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </Link>
                                                                ) : (
                                                                    <Link
                                                                        to=""
                                                                        onClick={item.handleClick}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </Link>
                                                                )
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>

                                            </Transition>
                                        </Menu>
                                    }
                                </div>
                            </div>
                        </div>

                        <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                            <div className="mx-auto max-w-3xl space-y-1 px-2 pb-3 pt-2 sm:px-4">
                            </div>
                            <div className="border-t border-gray-200 pb-3 pt-4">
                                {isAuthenticated &&
                                    <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                        </div>
                                        <button
                                            type="button"
                                            className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="absolute -inset-1.5"/>
                                            <span className="sr-only">{t("Favorite advertisements")}</span>
                                            <HeartIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                }
                                <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                                    {userNavigation.map((item) => (
                                        item.to ?
                                            <Link key={item.name} to={item.to}
                                                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                            >{item.name}
                                            </Link>
                                            :
                                            <Link
                                                to=""
                                                key={item.name}
                                                onClick={item.handleClick}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </Link>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </>
                )}
            </Popover>
        </>
    );
}

export default Header;