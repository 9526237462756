import React, {createContext, useContext, useEffect, useState} from 'react';
import {API_BASE_URL_WITH_PORT} from '../Config';

const CategoriesContext = createContext();

export const useAdTypes = () => useContext(CategoriesContext);

export const CategoriesProvider = ({children}) => {
    const [adTypes, setAdTypes] = useState([]);

    useEffect(() => {
        fetch(`${API_BASE_URL_WITH_PORT}/api/ad/categories`)
            .then(response => response.json())
            .then(data => setAdTypes(data))
            .catch(error => console.error('Error fetching ad types:', error));
    }, []);

    return (
        <CategoriesContext.Provider value={adTypes}>
            {children}
        </CategoriesContext.Provider>
    );
};
