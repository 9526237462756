import React, {Fragment, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {API_BASE_URL, API_BASE_URL_WITH_PORT} from '../Config';
import {useTranslation} from 'react-i18next';
import FSLightbox from 'fslightbox-react';
import ShowMapComponent from "../components/ShowMapComponent";
import {useAuth} from "../contexts/AccessTokenContext";
import ContactForm from "../components/ContactForm";
import {HeartIcon} from "@heroicons/react/24/solid";
import {Transition} from "@headlessui/react";
import {CheckCircleIcon} from "@heroicons/react/16/solid";
import {XMarkIcon} from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/LoadingSpinner";

const AdPage = ({onToggleFavorite}) => {
    const {id} = useParams();
    const [ad, setAd] = useState(null);
    const {t} = useTranslation();
    const [toggler, setToggler] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [imageSources, setImageSources] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [triggerToggle, setTriggerToggle] = useState(false);
    const {isAuthenticated, loginWithRedirect, accessToken} = useAuth();
    const [showForm, setShowForm] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {};
                if (accessToken) {
                    headers['Authorization'] = `Bearer ${accessToken}`;
                }
                const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/ad/${id}`, {
                    headers: headers,
                });
                const data = await response.json();
                setAd(data);
                setImageSources(data.images.map(image => `${API_BASE_URL}/${image}`));
            } catch (error) {
                console.error('Error fetching ad details:', error);
            }
        };

        if (triggerToggle) {
            setTriggerToggle(false);
        }
        fetchData();
    }, [id, triggerToggle]);

    function renderSpecificField(additionalFields) {
        return Object.entries(additionalFields).map(([fieldName, value]) => {
            return (
                <div key={fieldName}>
                    <p className="text-sm font-semibold">{t(fieldName.charAt(0).toUpperCase() + fieldName.slice(1))}:</p>
                    <p>{value}</p>
                </div>
            );
        });
    }

    const toggleMap = () => {
        setShowMap(true);
    };

    const handleFormOpen = () => {
        setShowForm(true);
    }

    const toggleFavorite = async () => {
        try {
            setShowAlert(true);
            if (accessToken !== null) {
                await fetch(`${API_BASE_URL_WITH_PORT}/api/adAuth/${id}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setTriggerToggle(true);
                setShowAlert(true);
            }
        } catch (error) {
            console.error(error);
        }
        onToggleFavorite();
    }

    return (
        <>
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={showAlert}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{t(ad?.isFavorite ? "Successfully added to favorite ads!" : "Successfully removed from favorites!")}</p>
                                        <p className="mt-1 text-sm text-gray-500">{t("You can see all your favorite ads in one place")}.</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setShowAlert(false)
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>

            <div className="max-w-3xl mx-auto mt-8">
                {ad ? (
                    <div className="max-w-3xl mx-auto mt-8">
                        <div className="flex justify-between items-center mb-4">
                            <h1 className="text-2xl font-bold">{ad.name}</h1>
                            {isAuthenticated ? (
                                <button onClick={() => toggleFavorite()}>
                                    <HeartIcon className={`h-6 w-6 ${ad.isFavorite ? 'text-red-500' : 'text-gray-400'}`}
                                               aria-hidden="true"/>
                                </button>
                            ) : (
                                <Link to={'/benefits/favorite'}>
                                    <HeartIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                </Link>
                            )}
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-sm font-semibold">{t("Category")}:</p>
                                <p>{ad.adType !== ad.category ? t(ad.adType) + " - " + t(ad.category) : t(ad.adType)}</p>
                                <p className="text-sm font-semibold">{t("Price")}:</p>
                                <p>{ad.price} Kč</p>
                                <p className="text-sm font-semibold">{t("Transaction type")}:</p>
                                <p>{t(ad.transactionCategory)}</p>
                            </div>
                            <div>
                                <p className="text-sm font-semibold">{t("Advertiser's name")}:</p>
                                <p>
                                    <Link to={`/user/${ad.user.id}`} className="text-blue-500 hover:underline">
                                        {ad.user.name}
                                    </Link>
                                </p>
                                {isAuthenticated ? (
                                    <>
                                        <p className="text-sm font-semibold">{t("Advertiser's phone number")}:</p>
                                        <p>{ad.user.phoneNumber}</p>
                                        <p className="text-sm font-semibold">{t("Advertiser's e-mail")}:</p>
                                        <p>{ad.user.email}</p>
                                    </>
                                ) : (
                                    <div
                                        className="border border-orange-400 rounded-md p-4 mb-4 flex items-center mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="h-12 w-12 mr-4 text-orange-400"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <circle cx="12" cy="12" r="10"/>
                                            <line x1="12" y1="16" x2="12" y2="12"/>
                                            <line x1="12" y1="8" x2="12" y2="8"/>
                                        </svg>
                                        <p className="text-sm text-orange-400">
                                            {t("Personal data of the advertiser is only accessible to logged-in users. Please")}{" "}
                                            <span onClick={loginWithRedirect}
                                                  className="text-blue-500 cursor-pointer underline hover:text-blue-600 focus:outline-none focus:underline">
                                            {t("Login")}
                                        </span>{" "}
                                            {t("or")}{" "}
                                            <span onClick={handleFormOpen}
                                                  className="text-blue-500 cursor-pointer underline hover:text-blue-600 focus:outline-none focus:underline">
                                            {t("Use the Contact Form")}
                                        </span>
                                        </p>
                                    </div>
                                )}
                                <ContactForm showForm={showForm} setShowForm={setShowForm}/>
                            </div>
                            <div>
                                <p className="text-sm font-semibold">{t("Address")}: <span onClick={toggleMap}
                                                                                           className="text-blue-500 cursor-pointer underline">{t("Show on Map")}</span>
                                </p>
                                <p>{ad.address.name + ", " + ad.address.location}</p>
                                <ShowMapComponent address={ad.address} showMap={showMap} setShowMap={setShowMap}/>
                            </div>
                            <div>
                                {renderSpecificField(ad.additionalFields)}
                            </div>
                            <p className="text-gray-600 mb-4">{ad.description}</p>
                            <div className="grid grid-cols-2 gap-4">
                                {ad.images.map((image, index) => (
                                    <div key={index} className="relative">
                                        <img
                                            src={`${API_BASE_URL}/${image}`}
                                            alt={`Image ${index}`}
                                            onClick={() => {
                                                setToggler(!toggler);
                                                setActiveIndex(index);
                                            }}
                                            className="w-full h-auto cursor-pointer rounded-md transition-opacity duration-300 hover:opacity-80"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <FSLightbox
                            toggler={toggler}
                            sources={imageSources}
                            types={[
                                ...new Array(imageSources.length).fill('image'),
                            ]}
                            sourceIndex={activeIndex}
                        />
                    </div>
                ) : (
                    <LoadingSpinner/>
                )}
            </div>
        </>
    );
};

export default AdPage;
