import {Auth0Provider} from "@auth0/auth0-react";
import {API_BASE_URL, AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from "./Config";
import {AuthProvider} from "./contexts/AccessTokenContext";

export const Auth0ProviderWithNavigate = ({children}) => {
    return (
        <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            audience={AUDIENCE}
            redirectUri={API_BASE_URL}
        >
            <AuthProvider>
                {children}
            </AuthProvider>
        </Auth0Provider>
    );
};