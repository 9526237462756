import React, {useEffect, useState} from 'react';
import {API_BASE_URL_WITH_PORT} from "../Config";
import {useTranslation} from "react-i18next";
import {useAuth} from "../contexts/AccessTokenContext";
import AdListItems from "../components/AdListItems";
import LoadingSpinner from "../components/LoadingSpinner";
import {Link} from "react-router-dom";

function MyAdsPage() {
    const {accessToken} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const {t} = useTranslation();
    const [ads, setAds] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (accessToken !== null) {
                    const userResponse = await fetch(`${API_BASE_URL_WITH_PORT}/api/adAuth/userAds`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    setAds(await userResponse.json())
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [accessToken]);

    return (
        <div className="max-w-6xl mx-auto mt-4 p-4 bg-white rounded-md shadow-md w-full">
            {isLoading ? (
                <LoadingSpinner/>
            ) : (
                <>
                    <div className="text-center mb-4">
                        <Link to={"/add-advertisement"}
                              className="button hover:bg-green-800 font-bold py-2 px-4 rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-1"
                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                            </svg>
                            {t("Add advertisement")}
                        </Link>
                    </div>
                    <AdListItems ads={ads} ownAds={true}/>
                </>
            )}
        </div>
    );
}

export default MyAdsPage;
