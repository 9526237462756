import React, {useEffect, useState} from "react";
import {API_BASE_URL_WITH_PORT} from "../Config";
import {useTranslation} from "react-i18next";
import SelectAddress from "./SelectAddress";
import {useAuth} from "../contexts/AccessTokenContext";
import LoadingSpinner from "./LoadingSpinner";

const UserInfo = ({address, setAddress}) => {
    const {t} = useTranslation();
    const [isToggled, setIsToggled] = useState(false);
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const {accessToken} = useAuth();

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const userResponse = await fetch(`${API_BASE_URL_WITH_PORT}/api/user/profile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (userResponse.ok) {
                const userData = await userResponse.json();
                const {address} = userData;

                setUserData(userData);
                setAddress(address);
                setLoading(false);
                console.log(userData)
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error while fetching user data:', error);
        }
    };

    const handleSubmitAddress = async () => {

        try {

            const updatedUser = {
                ...userData,
                address: address
            };

            const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/user/updateProfile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(updatedUser),
            });

            if (response.ok) {
                await fetchUserData();
                console.log("Address update OK.");
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update user data.');
            }
        } catch (error) {
            console.error(error.message);
        }
    };


    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const renderToggleBasedFields = () => {
        if (!isToggled) return null;

        return (
            <div className="mt-4">
                <div className="mb-4">
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        {t("Address")}
                    </label>
                    <SelectAddress address={address} setAddress={setAddress}/>
                </div>
                <button
                    onClick={handleSubmitAddress}
                    className="mt-4 button text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline">
                    {t("Save address to profile")}
                </button>
            </div>
        );
    };

    return (
        <div className="w-full lg:w-1/3 px-2 mb-4">
            <h2 className="text-xl font-bold mb-4">{t("Personal information")}</h2>
            {loading ? (
                <LoadingSpinner/>
            ) : (
                <>
                    <table>
                        <tbody>
                        <tr>
                            <td className="pr-7"><strong>{t("Name")}:</strong></td>
                            <td className="text-right">{userData?.name}</td>
                        </tr>
                        <tr>
                            <td className="pr-7"><strong>{t("Email")}:</strong></td>
                            <td className="text-right">{userData?.email}</td>
                        </tr>
                        <tr>
                            <td className="pr-7"><strong>{t("Phone number")}:</strong></td>
                            <td className="text-right">{userData?.phoneNumber}</td>
                        </tr>
                        <tr>
                            <td className="pr-7"><strong>{t("Address")}:</strong></td>
                            {userData?.address &&
                                (
                                    <td className="text-right">{userData.address.name + ", " + userData?.address?.location}</td>
                                )}
                        </tr>
                        </tbody>
                    </table>

                    <label className="flex items-center cursor-pointer">
                        <div className="relative">
                            <input type="checkbox" className="sr-only" checked={isToggled} onChange={handleToggle}/>
                            <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                            <div
                                className={`dot absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition ${isToggled ? 'button transform translate-x-full' : 'bg-white'}`}></div>
                        </div>
                        <div className="ml-3 text-gray-700 font-medium">
                            {t("Use another address for the advertisement")}
                        </div>
                    </label>
                    {renderToggleBasedFields()}
                </>
            )}
        </div>
    );
};

export default UserInfo;