import React, {useState} from 'react';
import AdList from "../components/AdList";
import CategoryFilters from "../components/CategoryFilters";

function HomePage() {
    const [filter, setFilter] = useState({});

    return (
        <div>
            <main className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">

                <div className="pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
                    <CategoryFilters filter={filter} setFilter={setFilter}/>

                    <section aria-labelledby="product-heading" className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
                        <h2 id="product-heading" className="sr-only">
                            Products
                        </h2>

                        <AdList filter={filter}/>
                    </section>
                </div>
            </main>
        </div>
    );
}

export default HomePage;
