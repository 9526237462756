import React, {useEffect, useState} from 'react';
import {API_BASE_URL, API_BASE_URL_WITH_PORT} from '../Config';
import {useTranslation} from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import {Link} from "react-router-dom";

const AdList = ({keyword, filter}) => {
    const [ads, setAds] = useState([]);
    const [noAds, setNoAds] = useState(false);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        const fetchAds = async () => {
            try {
                const requestFilter = {
                    ...filter, address: filter.address?.data
                };

                if (filter.demand && filter.offer) {
                    requestFilter.demand = false;
                    requestFilter.offer = false;
                }

                const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/ad/all`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(requestFilter)
                });

                if (response.ok) {
                    const data = await response.json();
                    setAds(data);
                    setNoAds(data.length === 0);
                } else {
                    console.error('Failed to fetch ads');
                }
            } catch (error) {
                console.error('Error while fetching ads:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAds();
    }, [keyword, filter]);

    const handleClick = (adId) => {
        fetch(`${API_BASE_URL_WITH_PORT}/api/ad/increaseViews/${adId}`)
            .then(response => {
                if (!response.ok) {
                    console.error('Failed to increase view count');
                }
            })
            .catch(error => {
                console.error('Error increasing view count:', error);
            });
    }

    return (
        loading ? <LoadingSpinner/> :
            <main className="pb-24">
                <section aria-labelledby="products-heading"
                         className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8">
                    <h2 id="products-heading" className="sr-only">
                        Advertisements
                    </h2>

                    <div
                        className="-mx-px grid grid-cols-2 border-l border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
                        {ads?.length > 0 ? (
                            ads.map((ad) => (
                                <Link to={`/ad/${ad.id}`} key={ad.id} onClick={() => handleClick(ad.id)}
                                      className="block">
                                <div className="group relative border-b border-r border-gray-200 p-4 sm:p-6">
                                    <div
                                        className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75">
                                        <img
                                            src={`${API_BASE_URL}/${ad.imagePath}`}
                                            alt={`Image ${ad.imagePath}`}
                                            className="h-full w-full object-cover object-center"
                                        />
                                    </div>
                                    <div className="pb-4 pt-10 text-center">
                                        <h3 className="text-xl font-bold mb-2">{ad.title}</h3>
                                        <div>
                                            <div>{new Date(ad.creationDate).toLocaleDateString('cs-CZ')}</div>
                                        </div>

                                        <div>
                                            {ad.adType !== ad.category ? t(ad.adType) + " - " + t(ad.category) : t(ad.adType)}
                                        </div>


                                        {ad.address && ad.address.region && (
                                            <div>
                                                {ad.address.region}
                                            </div>
                                        )}
                                        <div>
                                            {parseInt(ad.price).toLocaleString('cs-CZ')} Kč
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            ))
                        ) : (
                            <div className="col-span-full text-center py-8">
                                <h3 className="text-2xl font-bold mb-2">{t("No ads available for the selected filters.")}</h3>
                            </div>
                        )}
                    </div>
                </section>

                {/* Pagination */}

                {/*<nav*/}
                {/*    aria-label="Pagination"*/}
                {/*    className="mx-auto mt-6 flex max-w-7xl justify-between px-4 text-sm font-medium text-gray-700 sm:px-6 lg:px-8"*/}
                {/*>*/}
                {/*    <div className="min-w-0 flex-1">*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            Previous*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="hidden space-x-2 sm:flex">*/}
                {/*        /!* Current: "border-indigo-600 ring-1 ring-indigo-600", Default: "border-gray-300" *!/*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            1*/}
                {/*        </a>*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            2*/}
                {/*        </a>*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-indigo-600 bg-white px-4 ring-1 ring-indigo-600 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            3*/}
                {/*        </a>*/}
                {/*        <span className="inline-flex h-10 items-center px-1.5 text-gray-500">...</span>*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            8*/}
                {/*        </a>*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            9*/}
                {/*        </a>*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            10*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="flex min-w-0 flex-1 justify-end">*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100 focus:border-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-25 focus:ring-offset-1 focus:ring-offset-indigo-600"*/}
                {/*        >*/}
                {/*            Next*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</nav>*/}
            </main>
    );
};

export default AdList;
