import React, {useEffect, useRef} from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {API_MAPY_CZ_KEY} from '../Config';

const LogoControl = L.Control.extend({
    options: {
        position: 'bottomleft',
    },

    onAdd: function (map) {
        const container = L.DomUtil.create('div');
        const link = L.DomUtil.create('a', '', container);

        link.setAttribute('href', 'https://mapy.cz/');
        link.setAttribute('target', '_blank');
        link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
        L.DomEvent.disableClickPropagation(link);

        return container;
    },
});

const customIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});

const MapComponent = ({longitude, latitude}) => {
    const mapRef = useRef(null);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.flyTo([latitude, longitude], 13, {
                duration: 2,
            });
        }
    }, [longitude, latitude]);

    useEffect(() => {
        const logoControl = new LogoControl();
        if (mapRef.current) {
            mapRef.current.addControl(logoControl);
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.removeControl(logoControl);
            }
        };
    }, []);

    return (
        <MapContainer ref={mapRef} center={[latitude, longitude]} zoom={13} style={{width: '400px', height: '400px'}}>
            <TileLayer
                url={`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${API_MAPY_CZ_KEY}`}
                attribution='<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>'
            />
            <Marker position={[latitude, longitude]} icon={customIcon}>
                <Popup>Your location</Popup>
            </Marker>
        </MapContainer>
    );
};

export default MapComponent;
