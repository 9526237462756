import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {API_BASE_URL, API_BASE_URL_WITH_PORT} from "../Config";
import {useAuth} from "../contexts/AccessTokenContext";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "./LoadingSpinner";

const AdListItems = ({ads, ownAds}) => {
        const {t} = useTranslation();
        const {accessToken} = useAuth();
        const [isDeleteLoading, setIsDeleteLoading] = useState(false);

        useEffect(() => {
        }, [isDeleteLoading]);

        const deleteAd = async (adId) => {
            try {
                if (accessToken !== null) {
                    setIsDeleteLoading(true);
                    await fetch(`${API_BASE_URL_WITH_PORT}/api/adAuth/${adId}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }


        return (
            isDeleteLoading ? (
                <LoadingSpinner/>
            ) : (
                <>
                    {ads?.length === 0 ? (
                        <div className="flex flex-col items-center justify-center h-full mt-10">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                                Zatím nemáte žádné inzeráty
                            </h2>
                            <p className="text-gray-600">
                                Přidejte svůj první inzerát kliknutím na tlačítko výše.
                            </p>
                        </div>
                    ) : (
                        ads?.map((ad) => (
                            <div key={ad.id} className="block mb-4">
                                <div
                                    className="relative bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                                    {ownAds && (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                deleteAd(ad.id);
                                                setIsDeleteLoading(false);
                                            }}
                                            className="text-red-600 hover:text-red-800 text-xl"
                                        >
                                            {t("Delete ad")}
                                        </button>
                                    )}
                                    <Link to={`/ad/${ad.id}`} className="block">
                                        <div className="grid grid-cols-3 gap-4">
                                            <div className="col-span-2">

                                                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{ad.title}</h3>
                                                <p className="text-gray-600 mb-4">{ad.description}</p>

                                                {ownAds && (
                                                    <div
                                                        className="flex justify-between items-center text-gray-500 mb-2">
                                                        <span>{t("Views")}: {ad.viewCount}</span>
                                                    </div>
                                                )}
                                                <div className="flex justify-between items-center text-gray-500 mb-2">
                                                    <div>{new Date(ad.creationDate).toLocaleDateString('cs-CZ')}</div>
                                                </div>

                                                <div className="text-gray-700">
                                        <span
                                            className="font-semibold">{t("Category")}:</span> {ad.adType !== ad.category ? `${t(ad.adType)} - ${t(ad.category)}` : t(ad.adType)}
                                                </div>

                                                <div className="text-gray-700">
                                                    <span className="font-semibold">{t("Price")}:</span> {ad.price} Kč
                                                </div>
                                                {ad.address && ad.address.postCodeWithCity && (
                                                    <div className="text-gray-700">
                                            <span
                                                className="font-semibold">{t("Locality")}:</span> {ad.address.postCodeWithCity}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-1 flex justify-center items-center">
                                                {ad.imagePath && ad.imagePath.length > 0 && (
                                                    <img
                                                        src={`${API_BASE_URL}/${ad.imagePath}`}
                                                        alt={`Image ${ad.imagePath}`}
                                                        className="w-24 h-24 object-cover rounded-md"
                                                    />
                                                )}
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))
                    )}
                </>
            )
        );
    }
;

export default AdListItems;