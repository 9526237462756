import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import ProfilePage from "./pages/ProfilePage";
import AdPage from "./pages/AdPage";
import {CategoriesProvider} from "./contexts/CategoriesContext";
import {Auth0ProviderWithNavigate} from "./Auth0ProviderWithChildren";
import MyAdsPage from "./pages/MyAdsPage";
import UsersPage from "./pages/UsersPage";
import Footer from "./components/Footer";
import ProfileBenefitsPage from "./pages/ProfileBenefitsPage";
import AddAdPage from "./pages/AdAddPage";

function App() {
    const [triggerToggle, setTriggerToggle] = useState(false);

    return (
        <Router>
            <Auth0ProviderWithNavigate>
                <div>
                    <Header triggerToggle={triggerToggle}/>
                    <div className="app-container">
                        <div className="content">
                            <CategoriesProvider>
                                <Routes>
                                    <Route path="/my-ads" element={<MyAdsPage/>}/>
                                    <Route path="/profile" element={<ProfilePage/>}/>
                                    <Route path="/ad/:id" element={<AdPage
                                        onToggleFavorite={() => setTriggerToggle(prev => !prev)}/>}/>
                                    <Route path="/user/:id" element={<UsersPage/>}/>
                                    <Route path="/benefits/*" element={<ProfileBenefitsPage/>}/>
                                    <Route path="/add-advertisement" element={<AddAdPage/>}/>
                                    <Route path="*" element={<HomePage/>}/>
                                </Routes>
                            </CategoriesProvider>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </Auth0ProviderWithNavigate>
        </Router>
    );
}


export default App;