// i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationCS from './locales/cs.json';

const resources = {
    cs: {
        translation: translationCS
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'cs',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
