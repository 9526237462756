import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

const ContactForm = ({showForm, setShowForm}) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telephone: '',
        message: ''
    });
    const {t} = useTranslation();

    const handleFormClose = () => {
        setShowForm(false);
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can handle form submission here, such as sending data to a server
        console.log(formData);
        // Clear form fields
        setFormData({
            name: '',
            email: '',
            telephone: '',
            message: ''
        });
        // Close the form after submission
        handleFormClose();
    };

    return (
        <>
            {showForm && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10">
                    <div className="absolute inset-0 bg-gray-900 opacity-50" onClick={handleFormClose}></div>
                    <div className="relative z-20 bg-white p-4 rounded-lg shadow-lg"
                         style={{width: "90%", maxWidth: "600px"}}>
                        <h2 className="text-xl font-bold mb-4">{t("Contact Advertiser")}</h2>
                        <button onClick={handleFormClose}
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div className="p-2">
                                <label htmlFor="name" className="block mb-1">{t("Name")}</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange}
                                       required
                                       className="w-full border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2"/>
                            </div>
                            <div className="p-2">
                                <label htmlFor="email" className="block mb-1">{t("E-mail")}</label>
                                <input type="email" id="email" name="email" value={formData.email}
                                       onChange={handleChange} required
                                       className="w-full border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2"/>
                            </div>
                            <div className="p-2">
                                <label htmlFor="telephone" className="block mb-1">{t("Phone number")}</label>
                                <input type="tel" id="telephone" name="telephone" value={formData.telephone}
                                       onChange={handleChange}
                                       className="w-full border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2"/>
                            </div>
                            <div className="p-2">
                                <label htmlFor="message" className="block mb-1">{t("Message for advertiser")}</label>
                                <textarea id="message" name="message" value={formData.message} onChange={handleChange}
                                          required rows="5"
                                          className="w-full border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2"></textarea>
                            </div>
                            <div className="flex justify-end">
                                <button type="submit"
                                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">{t("Submit")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default ContactForm;
