import {createContext, useContext, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {API_BASE_URL_WITH_PORT, AUDIENCE} from "../Config";
import LoadingSpinner from "../components/LoadingSpinner";

// Create the AuthContext
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const {isAuthenticated, logout, getAccessTokenSilently, isLoading, loginWithRedirect} = useAuth0();
    const [accessToken, setAccessToken] = useState(null);
    const [user, setUser] = useState({});
    const [registerLoading, setRegisterLoading] = useState(true);


    useEffect(() => {
        const fetchAccessToken = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: AUDIENCE,
                        },
                    });
                    setAccessToken(token);

                    const user = await fetch(`${API_BASE_URL_WITH_PORT}/api/register`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setUser(await user.json());
                    setRegisterLoading(false);
                } catch (error) {
                    console.error('Error fetching access token:', error);
                }
            }

            setRegisterLoading(false);
        };

        fetchAccessToken();
    }, [isLoading]);

    return (
        isLoading || registerLoading ? (
            <LoadingSpinner center={true}/>
        ) : (
            <AuthContext.Provider
                value={{isAuthenticated, accessToken, logout, isLoading, loginWithRedirect, user, registerLoading}}>
                {children}
            </AuthContext.Provider>
        )
    );
};
