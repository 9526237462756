import React, {Fragment, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {API_BASE_URL_WITH_PORT} from '../Config';
import {useAdTypes} from "../contexts/CategoriesContext";
import {useNavigate} from "react-router-dom";
import {StyledDropzone} from "../components/StyledDropzone";
import UserInfo from "../components/UserInfo";
import {useAuth} from "../contexts/AccessTokenContext";
import {Dialog, Transition} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/24/outline'

const AddAdPage = () => {
    // state definitions
    const [adData, setAdData] = useState({
        id: '',
        name: '',
        price: '',
        description: '',
        transactionCategory: 'OFFER',
    });

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [address, setAddress] = useState({});
    const [optionalFields, setOptionalFields] = useState([]);
    const [additionalFields, setAdditionalFields] = useState({});
    const [open, setOpen] = useState(false);
    const [responseId, setResponseId] = useState('');
    const cancelButtonRef = useRef(null);

    // context and hooks
    const {accessToken} = useAuth();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const adTypes = useAdTypes();

    const handleChange = (e) => {
        const {name, value} = e.target;

        setAdData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        console.log(adData);
    };

    const handleAdditionalFieldChange = (e) => {
        const {name, value} = e.target;

        setAdditionalFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const handleCategoryChange = async (e) => {
        const selectedCategory = e.target.value;
        setSelectedCategory(selectedCategory);
        if (selectedCategory) {
            try {
                const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/ad/categories/${selectedCategory}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const fields = Object.entries(data).map(([, fieldValue]) => {
                        return (
                            <div key={fieldValue} className="w-full md:w-1/2 lg:w-1/3 px-2 mb-4">
                                <label htmlFor={fieldValue} className="block text-sm font-medium text-gray-700">
                                    {t(fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1))}
                                </label>
                                <input
                                    type="text"
                                    name={fieldValue}
                                    value={additionalFields.fieldValue}
                                    onChange={handleAdditionalFieldChange}
                                    className="mt-1 p-2 border rounded-md w-full"
                                />
                            </div>
                        );
                    });
                    setOptionalFields(fields);
                } else {
                    console.error('Failed to fetch category data:');
                    // Handle error
                }
            } catch (error) {
                console.error('Error fetching category data:', error);
                // Handle error
            }
        } else {
            setOptionalFields([]);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        selectedImages.forEach(file => formData.append('files', file));
        // first upload image
        try {
            const uploadResponse = await fetch(`${API_BASE_URL_WITH_PORT}/api/adAuth/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload images');
            }

            const adIdResponse = await uploadResponse.json();

            // prepare rest with received image id
            const adPayload = {
                ...Object.entries(adData).reduce((acc, [key, value]) => {
                    if (value !== '' && value !== null && value !== undefined) {
                        acc[key] = value;
                    }
                    return acc;
                }, {}),
                category: selectedCategory,
                address: address,
                adId: adIdResponse,
                additionalFields: {},
            };

            Object.entries(additionalFields).forEach(([name, value]) => {
                adPayload.additionalFields[name] = value;
            });

            const adResponse = await fetch(`${API_BASE_URL_WITH_PORT}/api/adAuth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(adPayload),
            });

            if (!adResponse.ok) {
                throw new Error('Failed to submit ad');
            }
            setOpen(true);
            setResponseId(adIdResponse);
        } catch (error) {
            console.error('Error during ad submission:', error.message);
        }
    };

    const handleNavigateClick = () => {
        setOpen(false);
        navigate(`/ad/${responseId}`);
    }


    // dynamic form rendering
    const renderAdditionalFields = () => {

        // handle description separately
        const descriptionField = (
            <div className="w-full px-2 mb-4">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    {t("Description")}:
                </label>
                <textarea
                    name="description"
                    value={adData.description}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full"
                    required
                />
            </div>
        );

        const requiredFields = ['title', 'price', 'offerDemand'].map((field) => (
            <div key={field} className="w-full md:w-1/2 lg:w-1/3 px-2 mb-4">
                {field === 'offerDemand' ? (
                    <div>
                        <label className="block text-sm font-medium text-gray-700">{t("Offer/Demand")}:</label>
                        <select
                            name="offerDemand"
                            value={adData.offerDemand}
                            onChange={handleChange}
                            className="mt-1 p-2 border rounded-md w-full"
                            required
                        >
                            <option value="offer">{t("Offer")}</option>
                            <option value="demand">{t("Demand")}</option>
                        </select>
                    </div>
                ) : (
                    <div>
                        <label htmlFor={field} className="block text-sm font-medium text-gray-700">
                            {t(field.charAt(0).toUpperCase() + field.slice(1))}
                        </label>
                        <input
                            type={field !== 'price' ? 'text' : 'number'}
                            name={field}
                            value={adData[field]}
                            onChange={handleChange}
                            className="mt-1 p-2 border rounded-md w-full"
                            required
                        />
                    </div>
                )}
            </div>
        ));

        return (
            <div className="flex flex-wrap">
                {requiredFields}
                {optionalFields}
                {descriptionField}
                <StyledDropzone selectedImages={selectedImages} setSelectedImages={setSelectedImages}/>
            </div>
        );
    };

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div
                                            className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3"
                                                          className="text-base font-semibold leading-6 text-gray-900">
                                                {t("The advertisement was successfully added")}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Informace o inzerátu a její správu naleznete v nabídce Moje
                                                    inzeráty.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                            onClick={() => handleNavigateClick()}
                                        >
                                            {t("Visit advertisement")}
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            {t("Cancel")}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className="max-w-6xl mx-auto mt-4 p-4 bg-white rounded-md shadow-md relative">
                <div className="flex flex-wrap -mx-2">
                    <UserInfo address={address} setAddress={setAddress}/>

                    <div className="w-full lg:w-2/3 px-2">
                        <h2 className="text-2xl font-bold mb-4 text-center">{t("Add advertisement")}</h2>
                        <form onSubmit={handleSubmit} className="flex flex-wrap justify-center">
                            <div className="w-full px-2 mb-4">
                                <label htmlFor="adType" className="block text-sm font-medium text-gray-700">
                                    {t("Advertisement type")}
                                </label>
                                <select
                                    name="adCategory"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    className="mt-1 p-2 border rounded-md w-full"
                                >
                                    <option value="">
                                        {t("Select advertisement category")}
                                    </option>
                                    {Object.entries(adTypes).map(([adType, categories]) => (
                                        categories.length !== 1 && (
                                            <optgroup key={adType} label={t(adType)} className="font-bold">
                                                {categories.map((category) => (
                                                    <option key={category} value={category}>{t(category)}</option>
                                                ))}
                                            </optgroup>
                                        )
                                    ))}
                                    {Object.entries(adTypes).map(([adType, categories]) => (
                                        categories.length === 1 && (
                                            <option key={adType} value={adType}
                                                    className="font-bold">{t(adType)}</option>
                                        )
                                    ))}
                                </select>
                            </div>
                            {renderAdditionalFields()}
                            <div className="w-full px-2 mb-4">
                                <button
                                    type="submit"
                                    className="button p-2 rounded-md hover:bg-green-800 focus:outline-none focus:shadow-outline-blue w-full"
                                >
                                    {t("Add advertisement")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );

};

export default AddAdPage;
