import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import {FunnelIcon} from "@heroicons/react/16/solid";
import {useAdTypes} from "../contexts/CategoriesContext";
import {t} from "i18next";
import Filters from "./Filters";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";

export default function CategoryFilters({setFilter}) {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [ownFilter, setOwnFilter] = useState({offer: true, demand: true});
    const adTypes = useAdTypes();


    const handleFiltersChange = () => {
        setFilter(ownFilter);
        if (mobileFiltersOpen) {
            setMobileFiltersOpen(false);
        }
    }

    const resetFilter = () => {
        setOwnFilter({offer: true, demand: true});
        setFilter({});
    }

    const handleAdTypeToggle = (category) => {
        const updatedFilter = {...ownFilter};
        updatedFilter.category = '';
        updatedFilter.adType = ownFilter.adType === category ? '' : category;
        setOwnFilter(updatedFilter);
        setFilter(updatedFilter);
    };

    const handleAdTypeHoverOut = () => {
        if (!ownFilter.adType) {
            setOwnFilter({...ownFilter, adType: ''});
        }
    };

    const handleCategoryClick = (category) => {
        setOwnFilter({...ownFilter, category});
        setFilter({...ownFilter, category});
    };

    const handleKeywordChange = (e) => {
        const {value} = e.target;
        setOwnFilter({...ownFilter, keyword: value});
        if (value === '') {
            setFilter(prevFilter => ({...prevFilter, keyword: value}));
        }
    };

    const handleClick = () => {
        setFilter(ownFilter);
    }

    return (
        <div className="bg-white">
            <div>
                {/* Mobile filter dialog */}
                <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25"/>
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel
                                    className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                    <div className="flex items-center justify-between px-4">
                                        <h2 className="text-lg font-medium text-gray-900">{t("Filters")}</h2>
                                        <button
                                            type="button"
                                            className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                            onClick={() => setMobileFiltersOpen(false)}
                                        >
                                            <span className="sr-only">Close menu</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>

                                    {/* Filters */}
                                    <div className="mt-4 border-t border-gray-200">
                                        <h3 className="sr-only">Categories</h3>
                                        {Object.entries(adTypes).map(([adType, category]) => (
                                            <li key={adType} className="block px-2 py-3">
                                                <button
                                                    className={`block px-2 py-3 ${ownFilter?.adType === adType ? 'font-bold' : ''}`}
                                                    onClick={() => handleAdTypeToggle(adType)}
                                                    onMouseLeave={handleAdTypeHoverOut}
                                                >
                                                    {t(adType)}
                                                </button>
                                                {ownFilter?.adType === adType && category.length > 1 && (
                                                    <ul className="px-2 py-3">
                                                        {category.map((subcategory, index) => (
                                                            <li key={index} className="mb-1">
                                                                <button
                                                                    className={`block px-2 py-3 ${ownFilter?.category === subcategory ? 'font-bold' : ''}`}
                                                                    onClick={() => handleCategoryClick(subcategory)}
                                                                >
                                                                    {t(subcategory)}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}

                                        <Filters mobile={true} filter={ownFilter} setFilter={setOwnFilter}/>
                                        <div className="mt-auto">
                                            <button
                                                onClick={handleFiltersChange}
                                                className="block w-full py-3 text-center button text-white font-semibold uppercase hover:bg-indigo-500"
                                            >
                                                {t("Show filtered advertisements")}
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center">
                        <div className="w-full relative">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="relative flex items-center">
                                <input
                                    id="search"
                                    name="search"
                                    className="block w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    placeholder={t("Keyword")}
                                    value={ownFilter.keyword}
                                    onChange={handleKeywordChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleClick();
                                        }
                                    }}
                                    type="search"
                                />
                                <button onClick={handleClick}
                                        className="ml-2 p-2 bg-gray-100 rounded-md focus:ring-2 focus:ring-inset focus:ring-green-800">
                                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </button>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                            onClick={() => setMobileFiltersOpen(true)}
                        >
                            <span className="sr-only">Filters</span>
                            {t("Filter")}
                            <FunnelIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>

                    <section aria-labelledby="products-heading" className="pt-6">
                        <h2 id="products-heading" className="sr-only">
                            Products
                        </h2>

                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            {/* Filters */}
                            <div className="hidden lg:block">
                                <h3 className="sr-only">Categories</h3>
                                <ul role="list"
                                    className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                                    {Object.entries(adTypes).map(([adType, category]) => (
                                        <li key={adType}>
                                            <button
                                                className={`block px-2 py-3 ${ownFilter?.adType === adType ? 'font-bold' : ''}`}
                                                onClick={() => handleAdTypeToggle(adType)}
                                                onMouseLeave={handleAdTypeHoverOut}
                                            >
                                                {t(adType)}
                                            </button>
                                            {ownFilter?.adType === adType && category.length > 1 && (
                                                <ul className="px-2 py-3">
                                                    {category.map((subcategory, index) => (
                                                        <li key={index} className="mb-1">
                                                            <button
                                                                className={`block px-2 py-3 ${ownFilter?.category === subcategory ? 'font-bold' : ''}`}
                                                                onClick={() => handleCategoryClick(subcategory)}
                                                            >
                                                                {t(subcategory)}
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                                <Filters mobile={false} filter={ownFilter} setFilter={setOwnFilter}/>

                                <div className="justify-end inline-flex mt-4">
                                    <button
                                        className={`rounded-md button px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-6`}
                                        onClick={handleFiltersChange}
                                    >
                                        {t("Show filtered advertisements")}
                                    </button>
                                    <button
                                        className={`rounded-md button px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-6`}
                                        onClick={resetFilter}
                                    >
                                        {t("Show all advertisements")}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}