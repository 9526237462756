import React, {Fragment, useEffect, useRef, useState} from 'react';
import {API_BASE_URL_WITH_PORT} from "../Config";
import {useTranslation} from "react-i18next";
import {useAuth} from "../contexts/AccessTokenContext";
import SelectAddress from "../components/SelectAddress";
import LoadingSpinner from "../components/LoadingSpinner";
import {Dialog, Transition} from "@headlessui/react";
import {CheckIcon} from "@heroicons/react/24/outline";

function ProfilePage() {
    const {logout, accessToken} = useAuth();
    const [userData, setUserData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        address: {name: '', location: '', latitude: '', longitude: ''},
    });
    const [error, setError] = useState(null);
    const {t} = useTranslation();
    const [address, setAddress] = useState({});
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const cancelButtonRef = useRef(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (accessToken !== null) {
                    const userResponse = await fetch(`${API_BASE_URL_WITH_PORT}/api/user/profile`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    const userData = await userResponse.json();
                    const {name, phoneNumber, email, address} = userData;

                    setUserData({name, phoneNumber, email, address});
                    setAddress(address);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [accessToken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL_WITH_PORT}/api/user/updateProfile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    name: userData.name,
                    phoneNumber: userData.phoneNumber,
                    address: address
                }),
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                const errorMessage = errorData?.message || 'Nepodařilo se aktualizovat údaje.';
                throw new Error(errorMessage);
            }
        } catch (error) {
            setError(error.message);
        }
        setOpen(true);
    };


    return (
        loading ? (
                <LoadingSpinner/>
            ) :
            <>
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div
                                className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel
                                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                        <div>
                                            <div
                                                className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3"
                                                              className="text-base font-semibold leading-6 text-gray-900">
                                                    {t("User information has been successfully updated")}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {t("The changed personal data will now be visible to other users")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense">
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>


                <div className="max-w-md mx-auto mt-4 p-4 bg-white rounded-md shadow-md">
                    <h2 className="text-2xl font-bold mb-4">{t("Profile")}</h2>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            E-mail:
                        </label>
                        <input
                            type="text"
                            name="email"
                            value={userData.email}
                            readOnly
                            className="mt-1 p-2 border rounded-md w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            {t("Name")}:
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={userData.name}
                            onChange={(e) => setUserData({...userData, name: e.target.value})}
                            className="mt-1 p-2 border rounded-md w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                            {t("Phone number")}:
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={userData.phoneNumber}
                            onChange={(e) => setUserData({...userData, phoneNumber: e.target.value})}
                            className="mt-1 p-2 border rounded-md w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                            {t("Address")}:
                        </label>
                        <SelectAddress address={address} setAddress={setAddress}/>
                    </div>
                    <div className="flex justify-end items-center space-x-4 pt-4">
                        <button
                            type="submit"
                            className="flex items-center justify-center space-x-2 h-10 w-auto bg-transparent hover:bg-blue-500 text-black text-sm font-semibold
                    py-2 px-2 border border-black hover:border-blue-500 rounded transition duration-150 ease-in-out"
                            onClick={handleSubmit}
                        >
                            <svg className="h-6 w-6 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/>
                                <polyline points="17 21 17 13 7 13 7 21"/>
                                <polyline points="7 3 7 8 15 8"/>
                            </svg>
                            <span>{t("Save changes")}</span>
                        </button>
                        <button
                            onClick={logout}
                            className="flex items-center justify-center space-x-2 h-10 w-auto bg-transparent hover:bg-red-500 text-black text-sm font-semibold
                    py-2 px-2 border border-black hover:border-red-500 rounded transition duration-150 ease-in-out"
                        >
                            <svg className="h-6 w-6 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"/>
                                <path d="M7 12h14l-3 -3m0 6l3 -3"/>
                            </svg>
                            <span>{t("Logout")}</span>
                        </button>
                    </div>
                </div>
            </>
    );
}

export default ProfilePage;
