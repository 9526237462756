import React, {useEffect} from 'react';
import MapComponent from "./MapComponent";
import {useTranslation} from "react-i18next";

const ShowMapComponent = ({showMap, setShowMap, address}) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (showMap && (!address || !address.longitude || !address.latitude)) {
            console.error(t("No address to show"));
        }
    }, [showMap]);

    const handleCloseMap = () => {
        setShowMap(false);
    };

    const handleMapClick = (event) => {
        event.stopPropagation();
    };

    return (
        <>
            {showMap && (
                address && address.longitude && address.latitude ? (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10"
                         onClick={handleCloseMap}>
                        <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
                        <div className="relative z-20 bg-white p-4 rounded-lg shadow-lg" onClick={handleMapClick}>
                            <MapComponent
                                latitude={address.latitude}
                                longitude={address.longitude}
                            />
                        </div>
                    </div>
                ) : null
            )}
        </>
    );
};

export default ShowMapComponent;
