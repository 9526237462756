import React from 'react';
import {useAuth} from "../contexts/AccessTokenContext";
import {useLocation} from "react-router-dom";

const ProfileBenefitsPage = () => {
    const {loginWithRedirect, isAuthenticated} = useAuth();
    const location = useLocation();
    const fromFavorite = location.pathname === '/benefits/favorite';

    return (
        <div className="py-12">
            <div className="max-w-4xl mx-auto px-4">
                <h1 className="text-3xl font-bold text-center mb-8">{!fromFavorite ? "Vytvářet inzerát může jen přihlášený uživatel"
                    : "Ukládat si inzeráty do oblíbených může jen přihlášený uživatel"}</h1>
                <div className="text-center mb-6">
                    {isAuthenticated ? null : (
                        <p>
                            Pro vytvoření inzerátu se prosím
                            <button className="text-green-600 underline ml-1"
                                    onClick={loginWithRedirect}>přihlaste</button>.
                        </p>
                    )}
                </div>
                <h3 className="text-xl font-semibold mb-4">Další benefity vašeho účtu</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-white p-6 rounded-lg shadow-md button">
                        <h2 className="text-xl font-semibold mb-4">Možnost vytvářet inzeráty</h2>
                        <p>Registrací získáte přístup k funkci vytváření vlastních inzerátů, což vám umožní sdílet své
                            nabídky s ostatními uživateli.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md button">
                        <h2 className="text-xl font-semibold mb-4">Detailní informace o inzerátech</h2>
                        <p>Po přihlášení budete mít možnost zobrazit detailní informace o inzerátech, včetně kontaktních
                            údajů inzerenta, což vám pomůže snadněji navázat kontakt.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md button">
                        <h2 className="text-xl font-semibold mb-4">Uložení inzerátů do oblíbených</h2>
                        <p>Po přihlášení budete mít také možnost ukládat si inzeráty do oblíbených, abyste je mohli
                            snadno sledovat a vrátit se k nim později.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileBenefitsPage;
