import {API_MAPY_CZ_KEY} from "../Config";
import React, {useEffect, useState} from "react";
import AsyncSelect from "react-select/async";
import {useTranslation} from "react-i18next";
import ShowMapComponent from "./ShowMapComponent";
import Utils from "../Utils";


const SelectAddress = ({address, setAddress}) => {
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const [showMap, setShowMap] = useState(false);


    useEffect(() => {
        let name = address?.name ?? 'No Address Selected';
        let location = address?.location ?? 'No Address Selected';
        let value = (name === 'No Address Selected' || location === 'No Address Selected') ? t("No Address Selected") : `${name}, ${location}`;

        setSelectedAddress({
            value: value,
            label: value,
            data: address
        });
    }, [address]);

    const fetchOptions = async (inputValue) => {
        const options = Utils.fetchMapyCzOptions(inputValue, API_MAPY_CZ_KEY);
            setLoading(false);
            return options;
    };

    const handleAddressChange = (selectedOption) => {
        if (setAddress) {
            setAddress(selectedOption?.data);
        }
    };

    const handleShowMap = () => {
        setShowMap(true);
    };


    const getCurrentPosition = async () => {
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const {latitude, longitude} = position.coords;
            const fetchData = await fetch(
                `https://api.mapy.cz/v1/rgeocode?lat=${latitude}&lon=${longitude}&apikey=${API_MAPY_CZ_KEY}`
            );
            const jsonData = await fetchData.json();
            if (jsonData.items.length > 0) {
                if (setAddress) {
                    setAddress({
                        name: jsonData.items[0].name,
                        location: jsonData.items[0].location,
                        latitude: jsonData.items[0].position.lat,
                        longitude: jsonData.items[0].position.lon,
                        region: jsonData.items[0].regionalStructure[2].name,
                    });
                }
            } else {
                console.log("No address found.");
            }
        } catch (error) {
            console.log(error);
            alert(
                t("Unable to retrieve your location. Please try again later.")
            );
        }
    };

    return (
        <>
            <div className="relative w-full max-w-md mt-4 pb-1">
                <AsyncSelect
                    className="w-full"
                    value={selectedAddress}
                    onChange={handleAddressChange}
                    loadOptions={fetchOptions}
                    placeholder={t("Enter your address...")}
                    isClearable
                    isLoading={loading}
                    noOptionsMessage={() => t("No results found")}
                    loadingMessage={() => t("Loading...")}
                />
            </div>
            <ShowMapComponent showMap={showMap} setShowMap={setShowMap} address={address}/>
            <div className="flex space-x-4 mt-1">
                <button
                    onClick={getCurrentPosition}
                    className="flex items-center justify-center space-x-1 h-10 w-full bg-transparent hover:bg-blue-500 text-black text-sm font-semibold 
                    py-1 px-2 border border-black hover:border-transparent rounded transition duration-150 ease-in-out flex-grow"
                >
                    <svg className="h-6 w-6 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z"/>
                        <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3"/>
                    </svg>
                    <span>{t("Get Current Position")}</span>
                </button>
                <button
                    onClick={handleShowMap}
                    className="flex items-center justify-center space-x-1 h-10 w-full bg-transparent hover:bg-blue-500 text-black text-sm font-semibold 
                    py-1 px-2 border border-black hover:border-transparent rounded transition duration-150 ease-in-out flex-grow"
                >
                    <svg className="h-6 w-6 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="11" r="3"/>
                        <path
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z"/>
                    </svg>
                    <span>{t("Show on Map")}</span>
                </button>
            </div>
        </>
    );
}

export default SelectAddress;