import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";

export function StyledDropzone({selectedImages, setSelectedImages}) {
    const [files, setFiles] = useState([]);
    const {t} = useTranslation();

    // update local state when selectedImages changes
    useEffect(() => {
        setFiles(selectedImages.map(file => ({
            file,
            preview: URL.createObjectURL(file)
        })));
    }, [selectedImages]);

    const onDrop = useCallback(acceptedFiles => {
        const newFiles = acceptedFiles.map(file => ({
            file,
            preview: URL.createObjectURL(file)
        }));

        setFiles(prevFiles => [...prevFiles, ...newFiles]); // update thumbnails
        setSelectedImages(prevSelected => [...prevSelected, ...acceptedFiles]);
    }, [setSelectedImages]);    // add images to images array

    const removeFile = (fileToRemove) => {
        setFiles(files.filter(({file}) => file !== fileToRemove));    // remove from thumbnail
        setSelectedImages(selected => selected.filter(file => file !== fileToRemove));  //  update actual data array
        URL.revokeObjectURL(fileToRemove.preview); // cleanup (must be to avoid some leaks idk)
    };

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop
    });

    return (
        <div className="px-2 mb-4 w-full">
            <div {...getRootProps({className: "flex flex-col justify-center items-center p-4 border-2 border-gray-300 border-dashed rounded-md cursor-pointer w-full"})}>
                <input {...getInputProps()} />
                <p className="text-gray-500">{t("Click here or drag images to upload")}</p>
            </div>
            <aside className="flex flex-wrap mt-2">
                {files.map(({file, preview}, index) => (
                    <div key={index} className="relative w-24 h-24 m-2 border rounded">
                        <img src={preview} alt={`preview ${index}`} className="w-full h-full object-cover"/>
                        <button
                            onClick={() => removeFile(file)}
                            className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 w-6 h-6 flex items-center justify-center"
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </aside>
        </div>
    );
}